import React from "react";
import SavviLogoImage from 'assets/images/savvi-logo-black.svg';
import { useNavigate } from "react-router-dom";
import './GalleryNavigation.styles.scss';
import { SAVVI_LINKS } from "constants/savvi";

const GalleryNavigation = ({
  backText = 'Back to Stems (Use Cases)',
  reloadText = 'Reload Demo',
  demoText = 'Request a Demo',
  demoDescriptionText = '',
  reloadAction = () => {},
  isFooter = false,
  isBackNavigation = true,
}) => {
  const navigate = useNavigate();
  return (
      <div className={`gallery-navigation-container gallery-navigation-container${isFooter ? '--bottom' : '--top'}`}>
         <img src={SavviLogoImage} alt="SAVVI AI" className={`gallery-navigation-logo ${
            isBackNavigation ? 'gallery-navigation-logo-block' : ''
          }`}/>
        <div className="gallery-navigation-header-container">
          {isBackNavigation ? (
            <div className="gallery-navigation-button-container">
              <div className="gallery-navigation-button-container" onClick={() => navigate('/')}>
                  <div className="gallery-navigation-icon">
                      <i className="now-ui-icons arrows-1_minimal-left" />
                  </div>
                  <p className="gallery-navigation-text">{backText}</p>
              </div>
              {!!reloadText && (
                <div
                  className="gallery-navigation-secondary-button"
                  onClick={reloadAction}
                >
                  {reloadText}
                </div>
              )}
            </div>
          ): <span />}
          {!!demoText && (
            <div className="gallery-navigation-tertiary-container">
              <p className="gallery-navigation-tertiary-text">{demoDescriptionText}</p>
              <button
                className="gallery-navigation-tertiary-action-button"
                onClick={()=> window.open(SAVVI_LINKS.CONTACT_US, "_blank")}
              >
                {demoText}
              </button>
            </div>
           
          )}
        </div>
      </div>
  );
}

export default GalleryNavigation;
