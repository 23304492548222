import React from "react";
import ReactJson from 'react-json-view'
import './CodeFooter.styles.scss';

const CodeFooter = ({ stemName, decideSrc, resultsSrc, decisionResults = null, displayResults = false }) => {
  return (
    <div className="body-content-footer">
      <div className="code-container">
        <div className="code-title-container">
          <p className='code-title-bold code-title-bold--green'>DECIDE</p>
          <p className='code-title'>- /stem/{stemName}/decide</p>
        </div>
        <ReactJson
          src={decideSrc}
          style={{ fontSize: '12px' }}
          theme="monokai"
          name="data"
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
        />
      </div>
      <div className="code-container">
        <div className="code-title-container">
          <p className='code-title-bold code-title-bold--yellow'>RESULTS</p>
          <p className='code-title'>- /stem/{stemName}/results</p>
        </div>
        {displayResults
          ? <p className='code-comment'>There are no results yet.</p>
          : <>
              {decisionResults && (
                <ReactJson
                  src={decisionResults}
                  style={{ fontSize: '12px' }}
                  theme="monokai"
                  name="decisionResults"
                  enableClipboard={false}
                  displayObjectSize={false}
                  displayDataTypes={false}
                />
              )}
              <ReactJson
                src={resultsSrc}
                style={{ fontSize: '12px' }}
                theme="monokai"
                name="resultsData"
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
              />
          </>
        }
      </div>
    </div>
  );
}

export default CodeFooter;
