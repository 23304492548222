export const SAVVI_STEM = {
    ICE_CREAM_PICKER: {
        name: 'tutorial_ice_cream_picker'
    },
    FIXED_TERM_LOAN: {
        name: 'personalized_term_loans_by_month'
    },
    FEATURED_CREDIT_PRODUCT: {
        name: 'suggest_a_credit_product'
    },
};
