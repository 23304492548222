import React from "react";
import { getIceCreamColor } from "utils/ice-cream";
import './IceCream.styles.scss';

const IceCream = ({
    width = '92px',
    height = '100%',
    flavor = null,
    name = '',
}) => {
    const { darkColor, mainColor, lightColor, cone } = getIceCreamColor(flavor);

    return (
        <>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0" y="0" width={width} height={height} viewBox="0, 0, 375, 808">
                <defs>
                    <clipPath id="Clip_1">
                    <path d="M53.091,348.574 L332.18,348.574 L332.18,806.275 L53.091,806.275 z"/>
                    </clipPath>
                    <clipPath id="Clip_2">
                    <path d="M50.441,40.16 L161.051,40.16 L161.051,180.675 L50.441,180.675 z"/>
                    </clipPath>
                    <clipPath id="Clip_3">
                    <path d="M124.159,276.665 L142.381,276.665 L142.381,293.01 L124.159,293.01 z"/>
                    </clipPath>
                    <clipPath id="Clip_4">
                    <path d="M170.917,38.835 L200.735,38.835 L200.735,64.26 L170.917,64.26 z"/>
                    </clipPath>
                </defs>
                <g id="cone">
                    <g opacity="0.68">
                    <path d="M53.09,348.575 L194.694,806.275 L332.182,353.115 z" fill={cone} />
                    </g>
                    <path d="M181.456,760.868 L202.16,779.939 L195.535,806.274 z" fill={cone}/>
                    <path d="M174.555,742.705 L229.214,692.758 L247.433,629.188 L164.617,708.196 z" fill={cone}/>
                    <path d="M146.602,650.075 L280.702,521.12 L302.093,453.918 L135.907,615.566 z" fill={cone}/>
                    <path d="M117.687,556.538 L320.588,359.473 L265.101,358.564 L105.265,514.764 z" fill={cone}/>
                    <path d="M152.47,349.483 L78.763,429.399 L87.873,462.091 L199.676,354.023 z" fill={cone}/>
                    <path d="M126.797,583.782 L231.974,681.86 L220.38,720.91 L146.673,650.076 z" fill={cone}/>
                    <path d="M73.794,414.869 L259.304,591.955 L248.537,629.189 L92.014,473.897 z" fill={cone}/>
                    <path d="M289.118,495.693 L277.523,532.926 L82.904,347.667 L140.048,347.667 z" fill={cone}/>
                    <path d="M220.38,350.391 L306.509,437.572 L318.103,401.246 L268.413,348.574 z" fill={cone}/>
                </g>
                <g id="ice-cream">
                    <g>
                    <path d="M345.043,339.024 C349.427,326.063 351.784,312.314 351.784,298.065 C351.784,220.422 282.064,157.472 196.035,157.472 C110.012,157.472 40.299,220.422 40.299,298.065 C40.299,312.314 42.653,326.063 47.036,339.024 C30.612,345.245 19.077,359.897 19.077,376.985 C19.077,399.72 39.485,418.141 64.672,418.141 C85.233,418.141 102.63,405.844 108.294,388.95 C113.986,405.844 131.355,418.141 151.94,418.141 C173.173,418.141 190.984,405.01 196.035,387.247 C201.104,405.01 218.91,418.141 240.139,418.141 C260.737,418.141 278.105,405.844 283.793,388.937 C289.457,405.844 306.822,418.141 327.411,418.141 C352.595,418.141 373.002,399.72 373.002,376.985 C373.002,359.897 361.463,345.256 345.043,339.024" fill={darkColor} />
                    <path d="M337.463,251.963 C340.444,238.807 341.336,224.888 339.851,210.523 C331.701,132.216 256.113,70.102 171.001,71.799 C85.873,73.495 23.488,138.355 31.615,216.656 C33.115,231.027 36.894,244.848 42.581,257.83 C26.986,264.428 17.104,279.428 18.894,296.664 C21.276,319.597 43.4,337.772 68.326,337.269 C88.692,336.872 104.599,324.126 108.43,306.973 C115.836,323.9 134.312,335.96 154.667,335.552 C175.693,335.135 191.938,321.541 195.08,303.525 C201.952,321.34 220.938,334.241 241.954,333.813 C262.345,333.414 278.232,320.668 282.092,303.51 C289.468,320.442 307.94,332.504 328.307,332.099 C353.22,331.601 371.475,312.621 369.104,289.694 C367.314,272.456 354.37,257.923 337.463,251.963" fill={mainColor}/>
                    <path d="M331.28,181.185 C336.204,167.793 339.102,153.681 339.663,139.164 C342.683,60.062 275.113,-1.077 188.701,2.635 C102.296,6.335 29.817,73.476 26.781,152.585 C26.236,167.098 28.067,181.017 31.961,194.025 C15.219,201.067 3.054,216.492 2.387,233.906 C1.505,257.071 21.283,274.963 46.588,273.87 C67.247,272.985 85.181,259.708 91.539,242.249 C96.6,259.219 113.561,270.996 134.23,270.114 C155.575,269.199 173.969,255.05 179.74,236.736 C184.129,254.612 201.502,267.225 222.845,266.312 C243.516,265.424 261.445,252.143 267.806,234.678 C272.849,251.663 289.81,263.44 310.49,262.552 C335.783,261.466 356.995,241.819 357.89,218.657 C358.553,201.244 347.542,186.827 331.28,181.185" fill={lightColor} />
                    <g opacity="0.27">
                        <path d="M143.209,40.513 C143.209,40.513 60.344,57.3 50.441,176.754 C50.441,176.754 57.067,187.653 71.976,173.121 C71.976,173.121 110.078,62.312 156.462,62.312 C156.462,62.312 173.028,36.88 143.209,40.513" fill="#FEFEFE"/>
                    </g>
                    <path d="M274.079,372.032 C274.079,379.556 268.888,385.656 262.483,385.656 C256.079,385.656 250.887,379.556 250.887,372.032 C250.887,364.507 256.079,358.408 262.483,358.408 C268.888,358.408 274.079,364.507 274.079,372.032" fill={mainColor}/>
                    <path d="M95.169,298.462 C95.169,304.481 91.09,309.361 86.058,309.361 C81.026,309.361 76.946,304.481 76.946,298.462 C76.946,292.442 81.026,287.563 86.058,287.563 C91.09,287.563 95.169,292.442 95.169,298.462" fill={mainColor}/>
                    <g opacity="0.4">
                        <path d="M142.381,284.838 C142.381,289.352 138.302,293.012 133.27,293.012 C128.238,293.012 124.159,289.352 124.159,284.838 C124.159,280.323 128.238,276.663 133.27,276.663 C138.302,276.663 142.381,280.323 142.381,284.838" fill={mainColor}/>
                    </g>
                    <path d="M74.558,370.215 L74.171,373.825 C73.745,377.793 70.435,380.658 66.816,380.191 L52.008,378.28 C48.389,377.813 45.776,374.184 46.202,370.215 L46.589,366.606 C47.015,362.637 50.325,359.772 53.944,360.239 L68.752,362.15 C72.372,362.617 74.984,366.247 74.558,370.215" fill={mainColor} />
                    <path d="M162.527,360.224 L160.337,360.224 C154.561,360.224 149.836,355.498 149.836,349.723 L149.836,345.293 C149.836,339.518 154.561,334.792 160.337,334.792 L162.527,334.792 C168.302,334.792 173.028,339.518 173.028,345.293 L173.028,349.723 C173.028,355.498 168.302,360.224 162.527,360.224" fill={mainColor} />
                    <g opacity="0.27">
                        <path d="M194.733,64.262 L176.917,64.262 C173.617,64.262 170.917,61.562 170.917,58.262 L170.917,44.833 C170.917,41.533 173.617,38.833 176.917,38.833 L194.733,38.833 C198.033,38.833 200.733,41.533 200.733,44.833 L200.733,58.262 C200.733,61.562 198.033,64.262 194.733,64.262" fill="#FEFEFE"/>
                    </g>
                    </g>
                </g>
            </svg>
            <h4 className="ice-cream-name">
                {name}
            </h4>
        </>
    );
}

export default IceCream;
