import GalleryNavigation from 'components/GalleryNavigation';
import UseCaseCard from 'components/UseCaseCard';
import { examples } from 'constants/examples';
import React from 'react';
import { useNavigate } from "react-router-dom";
import './ExamplesApp.styles.scss';

const ExamplesApp = () => {
  const navigate = useNavigate();
  return (
    <>
      <GalleryNavigation isBackNavigation={false} demoDescriptionText='Ready to Get SAVVI' />
      <div className="examples-app-container">
        <div className="examples-app-text-container">
          <p className="examples-app-text--bold">See SAVVI AI in Action</p>
          <p className="examples-app-text--regular">Want to learn more about how SAVVI uses power of Machine Learning powered decisioning?<br />See live examples of SAVVI Machine Learning APIs in action.</p>
        </div>
        <div className="examples-app-cards">
          {examples.map((useCase) => (
            <UseCaseCard
              title={useCase.name}
              description={useCase.description}
              category={useCase.category}
              categoryColor={useCase.categoryColor}
              iconSrc={useCase.iconSrc}
              iconAltText={useCase.name}
              handleClickAction={() => navigate(useCase.url)}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default ExamplesApp;
