import { ICE_CREAM_FLAVOR } from "constants/ice-cream";

export const getIceCreamColor = (flavor) => {
    switch (flavor) {
        case ICE_CREAM_FLAVOR.RASPBERRY:
            return {
                mainColor: '#EE569E',
                darkColor: '#F181B2',
                lightColor: '#F6B9D3',
                cone: '#F8AD48'
            };

        case ICE_CREAM_FLAVOR.STRAWBERRY:
            return {
                mainColor: '#EE569E',
                darkColor: '#F181B2',
                lightColor: '#F6B9D3',
                cone: '#F8AD48'
            };

        case ICE_CREAM_FLAVOR.CHOCOLATE:
            return {
                mainColor: '#A47551',
                darkColor: '#523A28',
                lightColor: '#D0B49F',
                cone: '#F8AD48'
            };
        
        case ICE_CREAM_FLAVOR.VANILLA:
            return {
                mainColor: '#F8EFCD',
                darkColor: '#F5EABC',
                lightColor: '#FAF5DD',
                cone: '#F8AD48'
            };

        case ICE_CREAM_FLAVOR.MAPLE_WALNUT:
            return {
                mainColor: '#DCD1A2',
                darkColor: '#C5BD9A',
                lightColor: '#F5EABC',
                cone: '#F8AD48'
            };

        default:
            return {
                mainColor: '#AEAEAE',
                darkColor: '#979797',
                lightColor: '#C5C5C5',
                cone: '#C5C5C5'
            };
    }
}
