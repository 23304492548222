import IceCreamIcon from 'assets/icons/ice-cream.svg';
import BankLoanIcon from 'assets/icons/fixed-term.svg';
import CreditProductIcon from 'assets/icons/credit-product.svg';

export const examples = [
    {
        url: '/ice-cream-picker',
        iconSrc: IceCreamIcon,
        name: 'Ice Cream Picker',
        description: 'This stem learns to increase order value and reduce cart abandonment by learning what flavor to suggest based on user preferences.',
        category: 'General',
        categoryColor: 'grey',
    },
    {
        url: '/featured-credit-product',
        iconSrc: CreditProductIcon,
        name: 'Featured Credit Product',
        description: 'This stem suggest a credit product.',
        category: 'Finance',
        categoryColor: 'blue',
    },
    {
        url: '/fixed-term-loan',
        iconSrc: BankLoanIcon,
        name: 'Personalize Fixed Term Loans',
        description: 'Personalize term loans by increasing loan acceptance and decreasing the number of days past-due of the first payment',
        category: 'Finance',
        categoryColor: 'blue',
    },
];
