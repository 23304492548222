import GalleryNavigation from "components/GalleryNavigation";
import React, { useRef } from "react";
import { ToastContainer } from 'react-toastify';
import './ExampleContainer.styles.scss';

const ExampleContainer = ({ children }) => {
  const childRef = useRef();

  const handleChildrenReload = () => {
    childRef.current.reloadDemoData();
  }

  return (
    <div className="example-container">
      <ToastContainer />
      <GalleryNavigation reloadAction={() => handleChildrenReload()}/>
      <div className="example-frame">
        {React.cloneElement(children, { ref: childRef })}
      </div>
      <GalleryNavigation isFooter reloadAction={() => handleChildrenReload()}/>
    </div>
  );
}

export default ExampleContainer;
