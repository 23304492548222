import React from "react";
import "./UseCaseCard.styles.scss";

const UseCaseCard = ({
    category = 'Other',
    categoryColor = 'grey',
    title = '',
    description = '', 
    iconSrc = null,
    iconAltText = '',
    handleClickAction = () => {}
}) => (
    <div className="use-case-container" onClick={handleClickAction}>
        <div className={`use-case-pill use-case-pill--${categoryColor}`}>{category}</div>
        <div className="use-case-image-container">
            <img src={iconSrc} alt={iconAltText} />
        </div>
        <p className="use-case-text use-case-text--bold">{title}</p>
        <p className="use-case-text use-case-text--regular">{description}</p>
    </div>
);

export default UseCaseCard;
